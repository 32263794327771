/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { PurchaseOrderFragment } from '../fragments';
import {
  OrderingEvaluationRequestField,
  OrderingOfferFields,
  SimplePurchaseOrderFields,
} from './fragment';
import {
  MasterEntityBasicField,
  CompanyDebtsSummary,
  ReceiverDebtsSummary,
} from '../customers/fragment';
import { MoneyFields } from '../moneyFields';
import { UserIdentification } from '../user/fragments';
import { CollectionActionsFields } from '../collection/fragments';

export const ORDERING_PURCHASE_ORDERS = gql`
  query purchaseOrders(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [String]
    $companyId: Int
    $orderBy: String
    $inSimulation: Boolean
    $inOffer: Boolean
    $inOperation: Boolean
    $inEvaluation: Boolean
    $inOperationActive: Boolean
    $finishedOperations: Boolean
    $inPendingInvoices: Boolean = false
    $availableForTransfer: Boolean = false
    $inCollection: Boolean = false
    $getRates: Boolean = true
    $globalFilter: String
    $purchaseOrderSecretKey: String
    $status: [String]
  ) {
    purchaseOrders(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id_In: $id_In
      companyId: $companyId
      orderBy: $orderBy
      inSimulation: $inSimulation
      inOffer: $inOffer
      inOperation: $inOperation
      inEvaluation: $inEvaluation
      inOperationActive: $inOperationActive
      finishedOperations: $finishedOperations
      inPendingInvoices: $inPendingInvoices
      availableForTransfer: $availableForTransfer
      inCollection: $inCollection
      globalFilter: $globalFilter
      purchaseOrderSecretKey: $purchaseOrderSecretKey
      status: $status
    ) {
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...PurchaseOrderFragment
          company {
            masterEntity {
              ...CompanyDebtsSummary
            }
          }
          purchaser {
            ...ReceiverDebtsSummary
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${PurchaseOrderFragment}
`;

export const PURCHASE_ORDER_DOCUMENT_PROFILE = gql`
  query PurchaseOrderDocumentProfile($purchaseOrderId: ID!) {
    purchaseOrder(purchaseOrderId: $purchaseOrderId) {
      id
      orderNumber
      pdfDocument
      company {
        id
        name
        executiveAssigned {
          id
          firstName
          lastName
          completeName @client
          picture {
            name
            url
          }
        }
      }
    }
  }
`;

export const PURCHASE_ORDER = gql`
  query purchaseOrder($purchaseOrderId: ID!) {
    purchaseOrder(purchaseOrderId: $purchaseOrderId) {
      ...SimplePurchaseOrderFields
      company {
        id
        name
        masterEntity {
          ...MasterEntityBasicField
          riskBlacklist {
            id
            comments
            createdAt
          }
        }
        executiveAssigned {
          ...UserIdentification
        }
      }
      purchaser {
        ...MasterEntityBasicField
      }
    }
  }
  ${SimplePurchaseOrderFields}
  ${MasterEntityBasicField}
  ${UserIdentification}
`;

export const HISTORIC_PURCHASE_ORDER_EVALUATION = gql`
  query historicPurchaseOrders(
    $first: Int
    $offset: Int
    $companyId: Int
    $status_In: [String]
    $orderBy: String
  ) {
    purchaseOrders(
      first: $first
      offset: $offset
      companyId: $companyId
      status_In: $status_In
      orderBy: $orderBy
    ) {
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...SimplePurchaseOrderFields
          company {
            id
            masterEntity {
              ...MasterEntityBasicField
              riskBlacklist {
                id
                comments
                createdAt
              }
            }
          }
          purchaser {
            ...MasterEntityBasicField
            riskBlacklist {
              id
              comments
              createdAt
            }
          }
          orderingsimulation {
            id
            evaluationRequest {
              ...OrderingEvaluationRequestField
            }
          }
          orderingoffer {
            ...OrderingOfferFields
          }
        }
      }
    }
  }
  ${SimplePurchaseOrderFields}
  ${OrderingEvaluationRequestField}
  ${OrderingOfferFields}
  ${MasterEntityBasicField}
`;

export const COUNT_ORDERING = gql`
  query countOrdering($companyId: Int) {
    available: purchaseOrders(companyId: $companyId, inSimulation: true) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
    offers: purchaseOrders(companyId: $companyId, inOffer: true) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
    activeOperations: purchaseOrders(
      companyId: $companyId
      inOperationActive: true
    ) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
    finishedOperations: purchaseOrders(
      companyId: $companyId
      finishedOperations: true
    ) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const CLIENT_ORDERING_INFORMATION = gql`
  query clientOrderingInformation($companyId: Int) {
    exposedPurchaseOrders: purchaseOrders(
      companyId: $companyId
      inOperationActive: true
      first: 1
    ) {
      totalCount
      aggregate(field: "orderingoffer__ordering_financed_amount")
      edges {
        cursor
        node {
          id
        }
      }
    }
    debtPurchaseOrders: purchaseOrders(
      companyId: $companyId
      inDebt: true
      first: 1
    ) {
      totalCount
      aggregate(field: "orderingoffer__ordering_financed_amount")
      edges {
        cursor
        node {
          id
        }
      }
    }
    finishedPurchaseOrders: purchaseOrders(
      companyId: $companyId
      inOperation: true
      first: 1
    ) {
      totalCount
      aggregate(field: "orderingoffer__ordering_financed_amount")
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const CLIENT_ORDERING_PROFILE = gql`
  query clientOrderingProfile(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $companyId: Int
    $inOperation: Boolean
  ) {
    purchaseOrders(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      companyId: $companyId
      inOperation: $inOperation
    ) {
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...SimplePurchaseOrderFields
          orderingDebt {
            id
            days
            debt {
              ...MoneyFields
            }
          }
          orderingoffer {
            ...OrderingOfferFields
          }
          company {
            id
            masterEntity {
              ...MasterEntityBasicField
            }
            executiveAssigned {
              ...UserIdentification
            }
          }
          purchaser {
            ...MasterEntityBasicField
          }
          collectionManager {
            id
            actions {
              ...CollectionActionsFields
              author {
                ...UserIdentification
              }
            }
          }
        }
      }
    }
  }
  ${SimplePurchaseOrderFields}
  ${OrderingOfferFields}
  ${MoneyFields}
  ${MasterEntityBasicField}
  ${UserIdentification}
  ${CollectionActionsFields}
`;

export const ORDERING_NUMBERS = gql`
  query purchaseOrders(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [String]
  ) {
    purchaseOrders(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id_In: $id_In
    ) {
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          orderNumber
        }
      }
    }
  }
`;
