import { gql } from '@apollo/client';
import { MoneyFields } from '../moneyFields';

export const GET_COLLECTION_MAIL_ENUM = gql`
  query GetCollectionMailEnum {
    getCollectionMailEnum {
      value
      label
    }
  }
`;

export const GET_RATIFICATION_MAIL_ENUM = gql`
  query GetRatificationMailEnum {
    getRatificationMailEnum {
      value
      label
    }
  }
`;

export const GET_PAYMENT_METHOD_ENUM = gql`
  query GetPaymentMethodEnum {
    getPaymentMethodEnum {
      value
      label
    }
  }
`;

export const GET_RATIFICATION_ACTIONS_ENUM = gql`
  query GetRatificationActionEnum {
    getRatificationActionEnum {
      value
      label
    }
  }
`;

export const GET_RATIFICATION_CHANNEL_ENUM = gql`
  query GetRatificationChannelEnum {
    getRatificationChannelEnum {
      value
      label
    }
  }
`;

export const GET_COLLECTION_PRIORITIES_OPTIONS = gql`
  query GetCollectionPrioritiesOptions {
    getCollectionPrioritiesOptions {
      value
      label
    }
  }
`;

export const GET_COLLECTION_MAIL_PREVIEW = gql`
  query GetCollectionMailPreview(
    $mailType: String!
    $collectionManagerIds: [Int]!
  ) {
    collectionMailPreview(
      mailType: $mailType
      collectionManagerIds: $collectionManagerIds
    )
  }
`;

export const GET_COLLECTIONS_BY_PRIORITY = gql`
  query GetCollectionsByPriority {
    getCollectionsByPriority {
      collectionPriority
      count
      amount
      debtors
    }
  }
`;

export const GET_COLLECTIONS_BY_EXPIRATION = gql`
  query GetCollectionsByExpiration {
    getCollectionsByExpiration {
      dateGroup
      values {
        priority
        count
        amount
      }
    }
  }
`;

export const GET_COLLECTIONS_BY_DEBT = gql`
  query GetCollectiosnByDebt {
    getCollectionsByDebt {
      expiredAmount
      unexpiredAmount
    }
  }
`;

export const GET_OPERATIVE_REQUEST_TYPE = gql`
  query GetOperativeRequestType {
    getOperativeRequestType {
      id
      code
      name
      shortName
      active
    }
  }
`;

export const GET_OPERATIVE_REQUEST = gql`
  query GetOperativeRequest($operativeRequestId: ID!) {
    getOperativeRequest(operativeRequestId: $operativeRequestId) {
      id
      status
      comment
      operativeRequestType {
        id
        shortName
        name
        code
      }
      operativerequestmanagerSet {
        id
        invoice {
          ... on InvoiceType {
            id
            folio
            dateToPay
            preoffer {
              id
              monthlyRate
              defaultRate
              retentionRate
            }
            offer {
              id
              monthlyRate
              defaultRate
              retentionRate
            }
            operation {
              id
              commission {
                ...MoneyFields
              }
            }
          }
        }
        purchaseOrder {
          ... on OrderingPurchaseOrderType {
            id
            orderNumber
            status
            orderingsimulation {
              id
              invoiceIssuedDate
              orderingMonthlyRate
              orderingDefaultRate
              orderingRetentionRate
            }
            orderingoffer {
              id
              invoiceIssuedDate
              orderingMonthlyRate
              orderingDefaultRate
              orderingRetentionRate
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
`;
